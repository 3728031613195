import { useEffect, useState } from "react";

interface UseMountReturn {
  mounted: boolean;
  unmounted: boolean;
}

const useMount = (): UseMountReturn => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return {
    mounted,
    unmounted: !mounted,
  };
};
export default useMount;
