import ContentBlock from "@/components/ContentBlock";
import Circular from "@/components/Circular";
import PostItem, { PostItemMode } from "@/features/post/components/PostItem";
import PostItemList from "@/features/post/components/PostItemList";
import PostReference from "@/features/post/components/PostRefrence";
import ProductVariationItemList from "@/features/product_variation/components/ProductVariationItemList";
import client from "@/utils/api/client";
import {
  LikeKind,
  ListItemKind,
  PostDetailResponse,
  PostPinKind,
  PostResponse,
  ProductVariationWithProductResponse,
} from "@/web-client/api";
import { ImageSize } from "@/consts/ImageSize";
import useAnalytics from "@/hooks/useAnalytics";
import useClientWindowSize from "@/hooks/useClientWindowSize";
import useDevice from "@/hooks/useDevice";
import useMount from "@/hooks/useMount";
import usePageView from "@/hooks/usePageView";
import usePagination from "@/hooks/usePagination";
import { FC, useMemo, CSSProperties } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Link from "next/link";
import AddListBtnWrapper from "@/features/list/components/AddListBtnWrapper";
import SimpleIconButton from "@/components/SimpleIconButton";
import LikeBtn from "@/features/like/components/LikeBtn";
import useLike from "@/features/like/hooks/useLike";
import ShareBtn from "@/components/ShareBtn";
import ProductVariationItem from "@/features/product_variation/components/ProductVariationItem";
import { NextSeo } from "next-seo";
import publicPath from "@/utils/publicPath";
import IconAdd from "../../../assets/imgs/svg/icon-add.svg";

interface Props {
  post: PostDetailResponse;
  setPost(project?: PostDetailResponse, shouldRevalidate?: boolean);
  scrollableTargetId?: string;
}

const PostShowTemplate: FC<Props> = ({
  post,
  setPost: mutate,
  scrollableTargetId,
}) => {
  usePageView(post.user ? post.maker_id : undefined);
  const { mounted } = useMount();
  const { selectContent } = useAnalytics(post.user ? post.maker_id : undefined);
  const { windowHeight } = useClientWindowSize();
  const { isSp } = useDevice();

  const { likeHandler } = useLike<PostDetailResponse>(
    post,
    LikeKind.POST,
    (updatedPost) => mutate(updatedPost, false),
    post.maker_id,
  );

  const productEventTrackingHandler = (
    productVariation: ProductVariationWithProductResponse,
  ): void => {
    const pin = productVariationPins.find(
      (pin) => pin.product_variation.id === productVariation.id,
    );
    selectContent("product_variation_pin", `PIN_${pin.id}`);
  };
  const {
    data: recommends,
    next,
    hasNext,
    dataLength,
    updateItem,
  } = usePagination<PostResponse>(
    `/posts/${post.id}/recommends`,
    20,
    async ({ limit, page }) => {
      const { data } = await client.postsIdRecommendsGet({
        id: post.id,
        limit,
        page,
      });

      return data;
    },
  );
  const hasRecommends = useMemo<boolean>(() => {
    return recommends.length > 0;
  }, [recommends]);

  const postItemStyle = useMemo<CSSProperties>(() => {
    return {
      maxHeight: `${post.upload_image.height}px`,
    };
  }, [post.upload_image.height]);

  const size = useMemo(() => {
    const image = post.upload_image;
    const imageWidth: number = image.width;
    const imageHeight: number = image.height;
    const ratio = imageWidth / imageHeight;

    const headerHeight = 60;
    const padding = 44 + 48;

    const areaHeight = windowHeight - (headerHeight + padding * 2);
    let width: number;
    let height: number;

    if (imageHeight >= areaHeight) {
      height = areaHeight;
      width = areaHeight * ratio;
    } else {
      height = imageHeight;
      width = imageWidth;
    }

    return { width, height };
  }, [windowHeight, post]);

  const containerStyle = useMemo<CSSProperties>(() => {
    const image = post.upload_image;

    return !isSp
      ? {
          width: `${Math.min(1000, size.width)}px`,
          maxWidth: `${image.width}px`,
        }
      : {};
  }, [post, size, isSp]);

  const productVariationPins = useMemo(
    () => post.pins.filter((p) => p.kind === PostPinKind.PRODUCT_VARIATION),
    [post],
  );

  return (
    <>
      <NextSeo
        title={post.project ? `${post.project.title}の写真` : `写真`}
        description={post.project ? post.project.description : null}
        openGraph={{
          images: [{ url: post.upload_image.urls.original }],
          type: "article",
        }}
        canonical={publicPath(`/posts/${post.id}`)}
      />
      <main
        className="relative laptop:pt-48 pb-120 mx-auto laptop:px-12"
        style={{
          maxWidth: 1024,
        }}
      >
        <div className="LayoutPostDetail__main space-y-20 laptop:space-y-64">
          <div className="space-y-32 laptop:space-y-120">
            <div
              style={containerStyle}
              className="imgContainer relative flex mx-auto flex-col w-full space-y-16"
            >
              <div style={postItemStyle}>
                {mounted && (
                  <div className="flex flex-col space-y-16">
                    <div className="space-y-8">
                      <PostItem
                        post={post}
                        mode={PostItemMode.Detail}
                        imageSize={ImageSize.Large}
                        mutate={(updatedPost) => mutate(updatedPost, false)}
                      />
                      <div className="space-y-8 px-16 laptop:px-0">
                        {post.caption && (
                          <p className="text-xs">{post.caption}</p>
                        )}
                        {post.use_types.length > 0 && (
                          <ul className="[&>*]:inline-block [&>*]:mr-8 [&>*]:pr-8 [&>*]:mb-8 [&>*]:border-r [&>*]:border-primary text-xs space-x-8-reverse leading-none">
                            {post.use_types.map((u) => (
                              <li key={u.id} className="last:border-r-0">
                                <Link
                                  href={`/architectures/posts/search?use_type_id=${u.id}`}
                                  className="text-link"
                                >
                                  {u.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>

                    {isSp && (
                      <div className="px-16 flex flex-row gap-8">
                        <LikeBtn
                          style={{ flex: 1 }}
                          hasLiked={post.has_liked}
                          likesCount={post.likes_count}
                          onClick={likeHandler}
                        />

                        <AddListBtnWrapper
                          selectedItem={post}
                          kind={ListItemKind.POST}
                        >
                          <SimpleIconButton>
                            <IconAdd width={16} height={16} />
                          </SimpleIconButton>
                        </AddListBtnWrapper>
                        <ShareBtn />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="space-y-32 laptop:space-y-40 small:px-16">
              <PostReference post={post} />
              <div className="space-y-48 laptop:space-y-64">
                {productVariationPins.length > 0 && (
                  <ContentBlock title="登録されている商品">
                    <ProductVariationItemList
                      productVariations={productVariationPins.map(
                        (pin) => pin.product_variation,
                      )}
                      onRender={(productVariation) => (
                        <ProductVariationItem
                          mutate={() => {
                            mutate();
                          }}
                          clickHandler={productEventTrackingHandler}
                          productVariation={productVariation}
                        />
                      )}
                    />
                  </ContentBlock>
                )}

                {hasRecommends && (
                  <ContentBlock title="空間用途が同じ写真">
                    <InfiniteScroll
                      style={{ overflow: "visible" }}
                      hasMore={hasNext}
                      next={next}
                      dataLength={dataLength}
                      loader={<Circular />}
                      scrollableTarget={scrollableTargetId}
                    >
                      <PostItemList
                        posts={recommends}
                        onRender={(post) => (
                          <PostItem
                            key={post.id}
                            post={post}
                            mutate={(updatedPost) =>
                              updateItem(updatedPost, false)
                            }
                          />
                        )}
                      />
                    </InfiniteScroll>
                  </ContentBlock>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PostShowTemplate;
