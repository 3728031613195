import client from "@/utils/api/client";
import useFetch from "@/hooks/useFetch";
import { PostDetailResponse } from "@/web-client/api";

interface PostHookResponse {
  post: PostDetailResponse;
  setPost(project: PostDetailResponse, shouldRevalidate?: boolean);
  error?: Error;
}

const usePost = (
  id: number,
  initialPost?: PostDetailResponse,
): PostHookResponse => {
  const {
    data: post,
    error,
    mutate: setPost,
  } = useFetch<PostDetailResponse>(
    `/posts/${id}`,
    async () => {
      const { data } = await client.postsIdGet({ id });

      return data;
    },
    initialPost,
  );

  return { post, error, setPost };
};

export default usePost;
