import { FC } from "react";
import Link from "next/link";
import Avatar, { AvatarSize } from "@/components/Avatar";

interface Props {
  href?: string;
  name?: string;
  imgSrc?: string;

  hasName?: boolean;
  hasLink?: boolean;
  size?: AvatarSize;
  layout?: AvatarWithLabelLayout;
}

export enum AvatarWithLabelLayout {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

const AvatarWithLabel: FC<Props> = ({
  href,
  name,
  imgSrc,
  size = AvatarSize.Normal,
  layout = AvatarWithLabelLayout.Horizontal,
  hasName = false,
  hasLink = true,
}): JSX.Element => {
  const wrapperClass = ["UserIcon", size, layout];
  const container = (child: JSX.Element): JSX.Element => {
    if (!hasLink || !href) {
      return child;
    }

    return (
      <Link href={href} prefetch={false}>
        {child}
      </Link>
    );
  };

  return (
    <div className={[...wrapperClass, "relative space-x-8"].join(" ")}>
      {imgSrc &&
        name &&
        container(<Avatar src={imgSrc} alt={name} size={size} />)}

      {hasName &&
        name &&
        container(
          <p className="text-xs overflow-hidden whitespace-no-wrap">{name}</p>,
        )}
    </div>
  );
};

export default AvatarWithLabel;
