import { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
  title: string;
  sub?: ReactNode;
}

const ContentBlock: FC<PropsWithChildren<Props>> = ({
  title,
  sub,
  children,
}) => {
  return (
    <section className="grid gap-8">
      <div className="grid">
        <h1 className="text-base laptop:text-lg font-bold">{title}</h1>
        {sub && <small className="text-secondary text-xs">{sub}</small>}
      </div>

      <div>{children}</div>
    </section>
  );
};
export default ContentBlock;
